/**
 * Add new promo coupon to cart
 * @param {Object} body 
 * @param {string} cartId
 * @returns Updated Cart
 */

import axios from "axios"

export default async function AddCoupon (body, cartId){
    return await axios.post(`/api/bigcommerce/checkout/coupons/${cartId}`, body, { timeout: 10000 })
        .then(response => {
            return{
                data: response.data.data.cart
            }
        })
        .catch(err => err)
}


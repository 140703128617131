/**
 * Get Cart By Id
 * @returns Product Items Array
 * We pluck out the product ids in the cart data and then make our 
 * request to get detailed product data for each item
 */

import axios from "axios";

export default async function GetCartById(id){
    return axios.get(`/api/bigcommerce/cart/${id}`)
        .then( async (response) => response.data.data)
        .catch(err => err)       
}

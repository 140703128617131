/**
 * Delete an individual cart item
 * @param {Number} itemId 
 * @param {string} cartId
 * @returns Cart Response
 */

import axios from "axios"

export default async function DeleteCartItem (itemId, cartId){
    return await axios.delete(`/api/bigcommerce/cart/${cartId}?itemId=${itemId}`)
        .then(data => data.data)
        .catch(err => err)

    // TO DO: then run a get cart to update the UI cart item indicators
}

import Card from "@/components/display/Card";
import Style from "./CartWidget.module.scss";
import formatPrice from "@/lib/utils/formatPrice";
import CheckoutBtn from "../CheckoutBtn";
// import Button from "@/components/forms/Button";


function Actions({ cartData }){
    return(
        <>
            {cartData?.base_amount && (
                <Card className={Style.actions}>
                    <ul className={Style.subtotal}>
                        <li className={Style.totalItem}>
                            <span>Shipping</span>
                            <span>FREE</span>
                        </li>
                        
                        <li className={Style.totalItem}>
                            <strong>
                                Subtotal
                            </strong>
                            <strong>
                                {formatPrice(cartData.base_amount)}
                            </strong>
                        </li>
                    </ul>
                    
                    <div className={Style.buttonRow}>
                        <CheckoutBtn id={cartData.id} />

                        {/* <Button 
                            variant="outline" 
                            type="link" 
                            href="/cart" 
                            size="full"
                        >
                            Review Cart
                        </Button> */}
                    </div>
                </Card>
            )}
        </>
    )
}

export default Actions;
"use client"

import { LocalStorage } from '@/services/LocalStorage.service';
import AddCartItem from '@/services/bigcommerce/cart/AddCartItem';
import CreateCartAddItem from '@/services/bigcommerce/cart/CreateCartAddItem';


// Global Add To Cart
export function AddToCart(body, buyNow = false){
    const cart = LocalStorage.getStorage("GRS_cart");

    return cart ? AddCartItem(body, cart?.id, buyNow).then(data => data) : CreateCartAddItem(body, buyNow).catch(err => err);
}


import { useContext } from "react";
import { GlobalContext } from "@/contexts/GlobalContext";
import CartWidget from "@/components/applications/cart-checkout/CartWidget";

export default function useCartWidget(){
    const [ _, dispatch ] = useContext(GlobalContext);
    
    function open(){
        dispatch({
            type: "setWidget",
            data:{
                isOpen: true,
                component: <CartWidget />,
                heading: `Your Shopping Cart`
            }
        })
    }


    return{
        open
    }

}
import CreateCartAddItem from "./CreateCartAddItem"
import DeleteCartItem from "./DeleteCartItem"

/**
 * Updates the last (or if 1 item) item in the cart
 * we have to first delete the item, then create a new cart with that 
 * updated item
 * @param {object} body 
 * @param {number} itemId 
 * @param {string} cartId 
 * @returns 
 */

export default async function UpdateLastCartItem (body, itemId, cartId){
    return await DeleteCartItem(itemId, cartId).then(async (res) => {
        return await CreateCartAddItem(body)
            .then(data => data.data)
            .catch(err => err)
    })
    .catch(err => err)
}

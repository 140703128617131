/**
 * Add a given product in cart 
 * @param {Object} body 
 * @param {string} cartId
 * @returns Updated Cart
 */

import axios from "axios";

export default async function AddCartItem(body, cartId = "", buyNow){
    return axios.post(`/api/bigcommerce/cart/${cartId}` + (buyNow ? "?buyNow=true" : ""), body, { timeout: 10000 })
        .then(data => data.data)
        .catch(err => err)
}

import Card from "@/components/display/Card";
import Style from "./CartItem.module.scss";
import Image from "next/image";
import Quantity from "@/components/product/Quantity";
import Link from "next/link";
import { IconTrash } from "@/icons/IconTrash";
import Price from "@/components/product/Price";
import { useCart } from "@/components/applications/cart-checkout/_hooks/useCart";
import createProductURL from "@/lib/utils/createProductURL";
import { useMemo } from "react";


function Item({
    id,
    image_url,
    cart_id,
    product_id,
    url = "",
    name,
    quantity,
    extended_list_price,
    options = []
}){

    const { itemMutation } = useCart()

    function removeItem(id){
        itemMutation.mutate({ type: "remove", productId: id, cartId: cart_id })
    }

    const product_url = useMemo(() => {
        return createProductURL(url.split("https://secure.gardenroute.store/")[1], product_id)

    }, [url, product_id])

    return(
        <Card className={Style.block}>
            <Link href={product_url} className={Style.imageLink}>
                <Image 
                    src={image_url} 
                    width={100} 
                    height={100} 
                    alt={name} 
                    className={Style.image}
                />
            </Link>

            <div className={Style.details}>
                <Link href={product_url} className={Style.detailLink}>
                    <p className={Style.title}>
                        {name}
                    </p>

                    {Array.isArray(options) && options.length > 0 && (
                        <small className={Style.options}>
                            {options.map((option, index) => (
                                <span key={option.valueId} className={Style.option}>
                                    {option.name}: {option.value} {index < options.length - 1 && ", "}
                                </span>
                            ))}
                        </small>
                    )}
                </Link>

                <div className={Style.priceRow}>
                    <Quantity 
                        qty={quantity} 
                        size="sm"
                        onChange={(qty) => console.log(qty)}
                    />

                    <Price value={extended_list_price} size="sm" />

                    <button 
                        type="button" 
                        className={Style.removeBtn}
                        onClick={() => removeItem(id)}
                    >
                        <IconTrash width="25" height="25" />
                    </button>
                </div>
            </div>
        </Card>
    )
}

export default Item;
/**
 * Get Cart 
 * @returns Product Items Array
 * We pluck out the product ids in the cart data and then make our 
 * request to get detailed product data for each item
 */

import axios from "axios";
import { LocalStorage } from "services/LocalStorage.service";

export default async function GetCart(){
    const cart = LocalStorage.getStorage("GRS_cart");

    if( cart?.id ){
        return await axios.get(`/api/bigcommerce/cart/${cart.id}`)
            .then( async (response) => response?.data?.data ?? [])
            .catch(err => err)       
    }
}

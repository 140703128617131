/**
 * Create a Cart and add a given product to cart 
 * @param {Object} body 
 * @returns New Cart with Item
 */

import axios from "axios"

export default async function CreateCartAddItem(body){
    return await axios.post(`/api/bigcommerce/cart`, body, { timeout: 10000 })
        .then(data => data.data)
        .catch(err => err)
}




/**
 * Normalizes the creation of the category page URLs
 * @param {string} url_path - fully qualified URL path (i.e. /seasonal/ )
 * @param {string} id 
 * @returns 
 */

export default function createCategoryURL({ url_path, id }){
    return "/b" + `${url_path}-/${id}`
}   
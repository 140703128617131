"use client"

import Button from "@/components/forms/Button";
import { IconLock } from "@/icons/IconLock";
import axios from "axios";
import { useEffect, useState } from "react";


function CheckoutBtn({ id }){
    const [ checkoutURL, setCheckoutURL ] = useState(null)

    useEffect(() => {
        if( id && !checkoutURL ){
            axios.post(`/api/bigcommerce/cart/redirect_urls/${id}`)
                .then(response => {
                    setCheckoutURL(response?.data?.data?.checkout_url)
                })
                .catch(err => console.log(err))
        }

    }, [ id ])


    return(
        <Button 
            isDisabled={!checkoutURL}
            size="full"
            color="ocean"
            onClick={() => window.location.href = checkoutURL}
        >
            <IconLock width="20" height="20" />
            Secure Checkout
        </Button>
    )
}

export default CheckoutBtn;
import Item from "./Item";
import Actions from "./Actions";
import WidgetBody from "@/components/display/WidgetBody";
import Style from "./CartWidget.module.scss";
import { GlobalContext } from "@/contexts/GlobalContext";
import { useContext } from "react";
import Card from "@/components/display/Card";

function CartWidget(){
    const [ global, _ ] = useContext(GlobalContext)

    return(
        <WidgetBody actions={<Actions cartData={global?.cart} />} noCard className={Style.content}>
            {global?.cart?.line_items && global.cart?.line_items?.physical_items.length ? (
                <>
                    {global.cart?.line_items?.physical_items.map((item) => {
                        const { id, url, product_id, image_url, name, quantity, extended_list_price, options } = item;

                        return(
                            <Item 
                                key={item.id}
                                cart_id={global?.cart?.id}
                                {...{ 
                                    id, 
                                    product_id, 
                                    url, 
                                    image_url, 
                                    name, 
                                    quantity, 
                                    extended_list_price,
                                    options 
                                }}
                            />
                        )
                    })}
                </>
            ):(
                <Card className={Style.empty}>
                    <h3>Your cart is empty</h3>
                </Card>
            )}          
        </WidgetBody>
    )
}

export default CartWidget;
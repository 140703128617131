
export const LocalStorage = {


    /**
     * Full storage setting of storage
     * @param {String} key 
     * @param {Object} data 
     * @param {String} type - the type of storage operation
     */

    setStorage({ key, data, type }){
        try{
            if (type === "local") {
                if (window?.localStorage) {
                    window.localStorage.setItem(key, JSON.stringify(data));
                }

            } else {
                if (window.sessionStorage) {
                    window.sessionStorage.setItem(key, JSON.stringify(data));
                }
            }

        }catch(err){}
    },

 

    /**
     * full service get storage
     * @param {String} key 
     */

    getStorage(key){
        try{
            if ( window?.localStorage ) {
                let stored = window.sessionStorage ? window.sessionStorage.getItem(key) : null;

                if ( !stored ) {
                    stored = window.localStorage ? window.localStorage.getItem(key) : null;
                }
                
                return stored !== "undefined" ? JSON.parse(stored) : null
            }

        }catch(err){}
    },



    /**
     * Full service adding to existing storage
     * @param {String} key 
     * @param {Object} data 
     * 
     * This item must have an unique ID or it will cause duplicate entries
     */

    addToStorage(key, data, maxItems = 25){
        try{
            let storedItem = LocalStorage.getStorage(key);
           
            if (Array.isArray(storedItem)) {
                
                // check to see if we have this item in the array
                const hasStoredItem = storedItem.some(item => item.id === data.id);

                if( hasStoredItem ){
                    const newStoredItem = storedItem.filter(item => item.id !== data.id);
                    newStoredItem.push(data);

                }else{
                    storedItem.push(data);
                }

                // if the array is longer than the max items allowed, remove the first item
                if (storedItem.length > maxItems) {
                    storedItem.shift();
                }

                LocalStorage.setStorage({ key, data: storedItem, type: "local" });

            } else {
                let arr = [];

                arr.push(data);
                LocalStorage.setStorage({ key, data: arr, type: "local" });
            }

        }catch(err){}
    },



    /**
     * Clear out storage object of specified key
     * @param {string} key 
     */

    remove(key){
        try{
            if( window.localStorage ){
                window.localStorage.removeItem(key)
                window.sessionStorage.removeItem(key)
            }
            
        }catch(err){}
    },



    
    getStoredCart(){
        return{}
    }

}
/**
 * Format Price
 *  - supports decimal place if the number has it, otherwise we don't use it.
 * @param {Integer} price 
 * @returns price string
 */

export default function formatPrice(price, locale = 'en-ZA', currency = 'ZAR' ){
    const newPrice = parseFloat(price);

    return newPrice.toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: Number.isInteger(newPrice) ? 0 : 2,
        maximumFractionDigits: 2,
    });
};


